import PortfolioCardItem from "./PortfolioCardItem.js";
import "./main.scss";
import qr from "../../assets/qr_generator.jpg";
import webdevtools from "../../assets/web_dev_tools.jpg";
import learnjs from "../../assets/learn_js.png";
import color from "../../assets/color-pallete.jpg";
import pln from "../../assets/pln_converter.jpg";
import arianamodelingimg from "../../assets/arianamodeling.jpg";
import gps from "../../assets/gps.jpg";
import ip from "../../assets/ipdata.png";
import esl from "../../assets/esl.png";
import speedTest from "../../assets/speed-test.png";

//---------------------------------------------------
// import ThredsPosts from "./ThredsPosts.js";

function Main() {
  const portfolioItems = [
    {
      key: "1",
      link: "https://asatryanhov.github.io/learn_front-end/",
      img: learnjs,
      alt: "site preview picture",
      title: "Education WEB site",
      descr: "HTML, CSS, JS, TS cheat sheets and more",
    },
    {
      key: "2",
      link: "https://asatryanhov.github.io/PLN_AMD_converter/",
      img: pln,
      alt: "site preview picture",
      title: "Converter",
      descr: "Converting PLN to USD , EUR and AMD",
    },
    {
      key: "3",
      link: "https://asatryanhov.github.io/color-pallets/",
      img: color,
      alt: "site preview picture",
      title: "Colors",
      descr: "There are  ready color pallets",
    },
    {
      key: "4",
      link: "https://asatryanhov.github.io/QR_generator/",
      img: qr,
      alt: "site preview picture",
      title: "QR generator",
      descr: "You can create your QR code free",
    },
    {
      key: "5",
      link: "https://asatryanhov.github.io/web-dev-tools/",
      img: webdevtools,
      alt: "site preview picture",
      title: "WEB Developer tools",
      descr: "Online resourses for WEB devs",
    },

    {
      key: "6",
      link: "https://ariana3dmodeling.pl/",
      img: arianamodelingimg,
      alt: "site preview picture",
      title: "Visit card site",
      descr: "visit card web page",
    },

    {
      key: "7",
      link: "https://asatryanhov.github.io/my-geoposition/",
      img: gps,
      alt: "geo-position",
      title: "Your geoposition coordinates",
      descr: "Check your coordinates and share it",
    },

    {
      key: "8",
      link: "https://asatryanhov.github.io/my-ip-address-info/",
      img: ip,
      alt: "your-ip",
      title: "IP address information",
      descr: "Check your ip address and info about it",
    },
    {
      key: "9",
      link: "https://asatryanhov.github.io/europa-super-league/",
      img: esl,
      alt: "esl",
      title: "European super league",
      descr: "A virtual league where  top clubs",
    },

    {
      key: "10",
      link: "https://asatryanhov.github.io/connection-speed-test/",
      img: speedTest,
      alt: "speed-tset",
      title: "Connection speed test",
      descr: "You can test your connection speed",
    },
  ];

  return (
    <div>
      <main>
        <section className="portfolio" id="portfolio">
          <div className="container">
            <h2 className="portfolio-header">Portfolio</h2>

            {/* <div className="portfolio-cards-wrapper">
              {portfolioItems.map((item, index) => (
                <PortfolioCardItem
                  key={index}
                  link={item.link}
                  img={item.img}
                  alt={item.alt}
                  title={item.title}
                  descr={item.descr}
                />
              ))}

              
            </div> */}
            <div className="portfolio-cards-wrapper">
              {portfolioItems
                .slice() // Creates a shallow copy of the array so the original is not mutated
                .reverse() // Reverses the copy of the array
                .map((item, index) => (
                  <PortfolioCardItem
                    key={index}
                    link={item.link}
                    img={item.img}
                    alt={item.alt}
                    title={item.title}
                    descr={item.descr}
                  />
                ))}
            </div>
          </div>
          {/* <ThredsPosts /> */}
        </section>
      </main>
    </div>
  );
}

export default Main;
